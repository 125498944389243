export const CONSTANTS = {
    CUSTOMER_TOKEN: 'V_CUSTOMER_TOKEN',
    CURRENT_CUSTOMIZATION: 'V_CURRENT_CUSTOMIZATION_ID',
    CURRENT_PRODUCT: 'V_CURRENT_PRODUCT_ID',
    V_CATEGORY: 'V_CATEGORY_ID',
    V_ORDER: 'V_ORDER_ID',
    V_PANELS: 'V_PANELS',
    Q_STRINGS: 'Q_STRINGS',
    JAR_LIMITS: 99,
}
